@import '../../styles/variables';

.header {
  &.analysis {
    background-color: $color-step-analysis-medium;

    .publicProjectButton {
      background-color: $color-step-analysis-dark;

      &:hover,
      &:focus,
      &:active {
        background-color: darken($color-step-analysis-dark, 15%) !important;
      }
    }
  }
  &.distribution {
    background-color: $color-step-distribution-medium;

    .publicProjectButton {
      background-color: $color-step-distribution-dark;

      &:hover,
      &:focus,
      &:active {
        background-color: darken($color-step-distribution-dark, 15%) !important;
      }
    }
  }
}
