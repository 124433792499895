@import '../../styles/variables';

.project {
  position: relative;
  border: 1px solid $color-gray-light;
  margin-bottom: 1rem;
  padding: 1rem;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: $text-color;

  &.isClickable {
    cursor: pointer;
    &:hover {
      background-color: rgba($color-blue-light, 0.5);
    }
  }

  .content {
    flex-grow: 1;
  }

  .deleteBtnContainer {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }

  p {
    margin-bottom: 0;
  }

  .projectProgressContainer {
    margin-top: 1rem;
    display: inline-block;
  }
}
