$thumbSize: 3.5rem;
$thumbSizeSmall: 2.5rem;

.distributionSliderContainer {
  height: 160px;
  width: 100%;
  background-color: grey;
  display: flex;
  align-items: center;
  position: relative;
  opacity: 1;
  transition: opacity 300ms;

  &.disabled {
    opacity: 0.7;
  }

  &.small {
    height: 60px;

    .thumb {
      width: $thumbSizeSmall;
      height: $thumbSizeSmall;
    }

    .sliderBackgroundContainer {
      left: 2rem + ($thumbSizeSmall * 0.5);
      right: 2rem + ($thumbSizeSmall * 0.5);
    }

    .leftBackgroundOffset {
      width: 2rem + ($thumbSizeSmall * 0.5);
    }

    p {
      font-size: 1.2rem;
      line-height: 1.2rem;
    }

    .leftLabelContainer,
    .rightLabelContainer {
      padding-top: 3px;
    }
  }

  .thumb {
    border: 2px solid #fff;
    width: $thumbSize;
    height: $thumbSize;
    box-shadow: none;
    border-radius: 50%;
    color: white;
    font-size: 1.3rem;
    line-height: 1.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
    &:focus {
      outline: none;
    }
  }

  p {
    color: #fff;
    text-align: center;
    margin-bottom: 0;
    font-size: 1.3rem;
    line-height: 1.3rem;
  }

  .sliderBackgroundContainer {
    position: absolute;
    left: 2rem + ($thumbSize * 0.5);
    right: 2rem + ($thumbSize * 0.5);
    height: 100%;
    z-index: 1;
    .left {
      height: 100%;
    }
  }
  .topBackground {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 50%;
    z-index: 1;

    p {
      text-transform: uppercase;
      margin-top: 25px;
    }
  }
  .leftBackgroundOffset {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 1;
    width: 2rem + ($thumbSize * 0.5);
  }
  .rightBackground {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  .leftLabelContainer {
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    z-index: 1;
    top: 50%;
    height: 50%;
    max-width: 95%;
    padding-top: 10px;

    p {
      width: 100%;
      padding-left: 1rem;
      white-space: nowrap;
    }
  }
  .rightLabelContainer {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    z-index: 1;
    top: 50%;
    height: 50%;
    max-width: 95%;
    direction: RTL;
    padding-top: 10px;

    p {
      width: 100%;
      padding-right: 1rem;
      white-space: nowrap;
    }
  }
}

.distributionSlider {
  flex-grow: 1;
  margin: 0 2rem;
  overflow-x: hidden;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 2;

  .track {
    background-color: white;
    height: 2px;
    width: 100%;
  }
}
