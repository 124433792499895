.sunburstModal {
  padding-top: 10rem;
  padding-left: 12rem;

  .header {
    padding-bottom: 1rem;
    color: white;

    .type {
      font-size: 1rem;
      margin: 0;
    }

    .title {
      line-height: 1.1;
    }
  }

  .footer {
    justify-content: center;
  }

  p {
    margin-bottom: 0;
  }
}
