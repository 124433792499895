.exampleStack {
  position: relative;
  width: 100%;
  height: 100%;

  .exampleCountIndicator {
    position: absolute;
    display: flex;
    right: 10px;
    bottom: 10px;
    z-index: 99;
    align-items: center;

    p {
      margin-bottom: 0;
      color: #ffffff;
      font-size: 22px;
    }
    .nextBtnContainer {
      margin-left: 0.5rem;
    }
  }

  .closeBtnContainer {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 99;
  }

  .examples {
    .example {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: 0.3s ease-out 0s;
      display: flex;
      flex-direction: column;
      border: 1px solid white;

      .title {
        padding: 10px 15px;
        margin: 0;
      }

      .imgContainer {
        height: 200px;
        width: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }

      .footer {
        margin: 10px;
        text-align: center;
      }

      @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
          z-index: 11 - $i;
          transform: scale(1 - (0.05 * ($i - 1)))
            translateY(-#{($i - 1) * 1.1}rem);
        }
      }
    }

    &.hasCloseBtn {
      .example {
        .title {
          padding-right: 35px;
        }
      }
    }
  }
}
