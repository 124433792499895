@import '../../../styles/variables';

.sunburstContainer {
  margin-top: -2rem;
  position: relative;
  display: inline-block;
}

.sunburstTitleTexts {
  padding-right: 140px;
  height: 50px;
}

.examplesContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  display: none;
  z-index: 10;

  &.show {
    display: block;
  }
}

.showHelpTextCheckbox {
  label {
    color: $color-blue;
    font-size: 1.2rem;
  }
}

.nextButtonContainer {
  margin-top: -4rem;
}

.zoomOutButton {
  position: absolute;
  left: 0;
  top: 0;
}
