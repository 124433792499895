.header {
  align-items: center;
  justify-content: flex-end;

  .alert {
    flex-grow: 1;
    margin-bottom: 0;

    button {
      margin: 0;
      padding: 0.75rem 1.25rem;
    }
  }

  .buttonsContainer {
    > * {
      margin-left: 10px;
    }
  }
}
