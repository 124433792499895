@import '../../../../../styles/variables';

.categoryHeader {
  position: relative;
  text-align: center;

  h1 {
    color: white;
    text-transform: uppercase;
    line-height: 1.4;
    margin-bottom: 0;
  }
}

.fixedcategoryHeader {
  @extend .categoryHeader;
  position: fixed;
  width: 100%;
  top: $header-height-project;
  left: 0;
}
