@import '../../../../../styles/variables';

.selectionMade {
  .answer {
    opacity: 0.6;

    &.selected {
      opacity: 1;
    }
  }
}

.noSelectionMade {
  .answer {
    opacity: 0.2;
  }
}

.answer {
  height: 100%;
  transition: 0.2s ease 0s;
  font-weight: 700;
  font-size: 1.1rem;
  line-height: 1.1rem;
  padding: 1rem 0.9rem;
  border: 0 solid white;
  border-left-width: 0.1rem;
  border-right-width: 0.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 75px;

  p {
    margin-bottom: 0;
    color: $text-color-dark;
  }

  &.selected {
    border-width: 0.6rem;
    padding: 0.4rem;
    cursor: default;
    &:hover {
      opacity: 1;
    }
  }

  &.selectable {
    cursor: pointer;
    &:hover {
      opacity: 0.75;
    }
  }
}

.unknownAnswer {
  background-image: repeating-linear-gradient(
    to right,
    transparent,
    transparent 4px,
    #ffffff 5px,
    #ffffff 6px
  );
  font-size: 0.9rem;
  line-height: 0.9rem;
  margin: auto;
}

.public {
  .answer {
    min-height: 3rem;
    &.selected {
      border-width: 0;
      padding: 0;
    }
  }
  &.selectionMade {
    .answer {
      opacity: 0.4;

      &.selected {
        opacity: 1;
      }
    }
  }
}

.answerCount {
  background-color: $color-gray-lighter;
  border-right: 1px solid white;
  border-left: 1px solid white;
  text-align: center;
}
