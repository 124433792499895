@import '../../../styles/variables';

.message {
  width: 100%;
  padding: 1rem;
  text-align: center;

  h3 {
    margin-bottom: 0;
  }
}
