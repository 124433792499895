@import '../../../../../styles/variables';

.helpText {
  position: absolute;
  pointer-events: none;
  z-index: 9;
  color: $color-blue;
  font-family: 'pompiere_regular', Helvetica, Arial;
  font-size: 1.8rem;
  line-height: 1.8rem;
  background-image: url(assets/arrow.svg);
  background-repeat: no-repeat;

  &.helpText0 {
    background-position: 0 64px;
    height: 168px;
    background-size: 85px;
    width: 240px;
    right: -140px;
    top: 0;
    text-align: center;
  }

  &.helpText1 {
    background-position: 0 35px;
    background-size: 85px;
    text-align: center;
    height: 140px;
    width: 155px;
    right: 10px;
    top: 21px;
  }

  &.helpTextReturn {
    background-image: url(assets/arrow-long.svg);
    background-size: 225px;
    height: 47px;
    width: 261px;
    right: 0;
    top: 233px;
    text-align: right;
  }

  &.helpText2 {
    background-position: 0 35px;
    background-size: 71px;
    text-align: center;
    height: 140px;
    width: 115px;
    right: 15px;
    top: 25px;
  }
}
