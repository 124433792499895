.groupLabel {
  text-transform: uppercase;
}

.distributionAnswerRow {
  height: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;

  .sliderBackgroundContainer {
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    z-index: 1;
    .left {
      height: 100%;
    }
  }

  .rightBackground {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  .leftLabelContainer {
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    z-index: 1;
    top: 50%;
    height: 50%;
    max-width: 95%;

    .label {
      color: white;
      width: 100%;
      padding-left: 1rem;
      white-space: nowrap;
      text-align: center;
    }
  }
  .rightLabelContainer {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    z-index: 1;
    top: 50%;
    height: 50%;
    max-width: 95%;
    direction: RTL;

    .label {
      color: white;
      width: 100%;
      padding-right: 1rem;
      white-space: nowrap;
      text-align: center;
    }
  }
}

.name {
  margin-bottom: 0;
  line-height: 1;
}
