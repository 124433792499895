.stepIntroContainer {
  position: relative;
  min-height: 100%;
  display: flex;
  align-items: center;

  .stepIntro {
    flex-grow: 1;
    background-color: white;

    .content {
      width: 40%;

      .buttonContainer {
        position: relative;

        .continueButton {
          width: 250px;
          height: 180px;

          .label {
            height: 100%;
            padding-top: 40px;
          }
        }

        .skipButton {
          width: 140px;
          height: 100px;
          position: absolute;
          right: -50px;
          bottom: -40px;

          .label {
            height: 100%;
            padding-top: 20px;
          }
        }
      }
    }
  }
}
