@import '../../styles/variables';

.header {
  //background-color: $color-blue-light;
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
}

.title {
  line-height: 1.1;
}

.closeBtnContainer {
  height: 29px;
  display: flex;
  align-items: center;
  margin-right: -1rem;
}
