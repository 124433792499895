@import 'src/styles/variables';

.rightColumn {
  ul {
    padding-left: 17px;
    word-wrap: break-word;

    li {
      margin-bottom: 1rem;
    }
  }
}
