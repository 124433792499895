@import '../../styles/variables';

$loaderSize: 75px;
.loader {
  width: $loaderSize;
  height: $loaderSize;
  background-image: url(./assets/loader.gif);
  background-size: 40px;
  background-position: center;
  background-repeat: no-repeat;
}

.fixedLoader {
  background-color: rgba(white, 0.7);
  box-shadow: 0 0 3px $color-blue;
  border-radius: 50%;
  width: $loaderSize;
  height: $loaderSize;
  position: fixed;
  z-index: 99999;
  top: 25%;
  left: 50%;
  margin-left: -($loaderSize/2);
  margin-top: -($loaderSize/2);
  background-image: url(./assets/loader.gif);
  background-size: 40px;
  background-position: center;
  background-repeat: no-repeat;
}

.saveLoader {
  position: fixed;
  z-index: 9999;
  bottom: 50px;
  right: 50px;
  padding: 10px;
  background-color: rgba(white, 0.7);
  box-shadow: 0 0 3px $color-blue;
  color: $color-blue;
  width: 191px;
  font-size: 1.2rem;
  display: flex;
  align-items: center;

  p {
    margin-bottom: 0;
  }

  .loaderImage {
    width: 40px;
    height: 36px;
    background-image: url(./assets/loader.gif);
    background-size: 40px;
    background-repeat: no-repeat;
    float: left;
    margin-right: 10px;
  }
}
