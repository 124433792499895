@import '../../styles/variables';

.header {
  background-color: white;
  box-shadow: 0 0 8px rgba(black, 0.4);
  padding: 0;
  min-width: 1024px;
  transition: height 300ms;
  height: $header-height;

  h1 {
    font-size: 30px;
  }
  h3 {
    font-size: 18px;
  }
  h1,
  h3 {
    color: $color-navy-dark;
  }

  .content {
    background-color: white;
  }

  &.inProject {
    height: $header-height-project;
    .content {
      height: $header-height-project - 8px;
    }
  }

  .headerBorder {
    top: 0;
    height: 8px;
    width: 100%;
    background-color: $color-gray-light;
    text-align: center;

    &.new {
      background-color: $color-step-new;
    }
    &.analysis {
      background-color: $color-step-analysis;

      .progressBar {
        background-color: #9572d1;
      }
    }
    &.distribution {
      background-color: $color-step-distribution;
    }
    &.result {
      background-color: $color-step-result;
    }
    &.detail {
      background-color: $color-step-detail;
    }

    .progressBar {
      background-color: $color-gray-medium;
      height: 8px;
      color: white;
      font-size: 12px;
      line-height: 8px;
      transition: width 300ms;
    }
  }

  .logo {
    margin-right: 1rem;
  }
  h1,
  h3 {
    margin: 0;
  }
}

.headerButtons {
  display: flex;
  height: $header-height - 10px;

  .active {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: 0.1rem;
      left: 0.3rem;
      right: 0.3rem;
      border-bottom: 4px solid $color-blue;
    }
  }
}

.languageButtons {
  position: relative;
  padding-left: 0.7rem;

  &:before {
    content: '';
    position: absolute;
    left: 0.35rem;
    top: 0.6rem;
    bottom: 0.6rem;
    border-left: 2px solid $color-blue;
  }
}

.homeBtnContainer {
  margin-right: 1rem;
}
