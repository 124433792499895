@import '../../styles/variables';

.faqs {
  .faq {
    border: none;
    border-radius: 0;

    .head {
      background: none;
      border: none;
      padding: 0;
      margin-bottom: 0;
      display: flex;
      cursor: pointer;
      border-bottom: 4px solid $color-blue;
      min-height: 45px;
      align-items: center;
      flex-grow: 1;

      .title {
        flex-grow: 1;
        padding: 0.4rem;
      }

      i {
        padding: 0.5rem;
      }

      h3 {
        margin-bottom: 0;
      }
    }

    .content {
      padding: 1rem 0.4rem;
      border-bottom: 1px solid $color-blue;
    }
  }
}
