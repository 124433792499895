@import '../../../../../styles/variables';

#sunburst {
  &.interactive {
    svg path {
      cursor: pointer;
    }
  }

  &.not_interactive {
    .mechanism,
    .intervention {
      display: none;
    }
  }
}
.slice .hidden-arc {
  fill: none;
}

.slice text {
  pointer-events: none;
  dominant-baseline: middle;
  text-anchor: middle;
}

.text-category {
  text-transform: uppercase;
  font-size: 1.1rem;
}

.analysis-empty-notice {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 62%;
  text-align: center;

  .notice {
    color: $color-blue;
    font-family: 'pompiere_regular', Helvetica, Arial;
    font-size: 28px;
    line-height: 1.1;
  }
}
