@import '../../../../../styles/variables';

.question {
  flex-grow: 1;
  padding-right: 0.5rem;
  span[intake-info] {
    color: $color-blue;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.answersContainer {
  height: 165px;
}

.name {
  margin-bottom: 0;
  line-height: 1;
}

.explanation {
  background-color: $color-gray-lighter;
  padding: 0.5rem;
  min-height: 2.2rem;

  p {
    margin-bottom: 0;
    white-space: pre-wrap;
  }
}

.showPublicAnswersButton {
  display: flex;
  align-items: center;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
  .showPublicAnswersButtonArrow {
    width: 1rem;
    margin-right: 0.5rem;
  }
}
