.saveBtn {
  background: none;
  border: none;
  transition: opacity 200ms;
  width: 30px;
  height: 30px;
  padding: 0;

  &:hover {
    opacity: 0.7;
  }
}
