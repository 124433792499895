@import '../../../../../styles/variables';

$checkboxSize: 20px;
$checkboxBorderSize: 2px;

.distributionFilterItem {
  margin-top: 0.65rem;
  padding-left: 1.5rem;
}

.valueBlock {
  width: 30px;
  height: 30px;
  background-color: $color-target-group-undefined;
  color: $color-gray;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}

.valueBar {
  height: 100%;
}
