.sunburstTitleTexts {
  text-align: center;

  h3 {
    margin: 0;
    font-weight: bold;
  }
  p {
    margin: 0;
    font-weight: bold;
  }
}
