@import '../../../../../styles/variables';

.distributionResultRow {
  margin: 1px 0;
}

.valueLabelContainer {
  display: flex;
  align-items: center;

  .valueLabel {
    font-size: 32px;
    margin: 0;
    padding-left: 7rem;
  }
}

.valueBlock {
  width: 75px;
  height: 75px;
  background-color: $color-target-group-undefined;
  color: $color-gray;
  font-size: 24px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}

.valueBar {
  height: 100%;
  transition: width 300ms ease-in;
}
