.distributionResultBar {
  height: 25px;
  display: flex;
  .item {
    height: 100%;
  }
  .undefined {
    @extend .item;
    width: 100%;
  }
}
