.buttonUnderlined {
  color: white;
  background: none;
  border: none;
  height: 28px;

  &.active,
  &:hover {
    border-bottom: 2px solid white;
  }
}
