@import '../../../../../styles/variables';

.helpText {
  position: absolute;
  pointer-events: none;
  z-index: 9;
  color: $color-blue;
  font-family: 'pompiere_regular', Helvetica, Arial;
  font-size: 1.6rem;
  line-height: 1.7rem;
  background-image: url(assets/arrow.svg);
  background-repeat: no-repeat;

  &.helpText0 {
    background-position: 27px 90px;
    height: 230px;
    background-size: 74px;
    width: 258px;
    right: -149px;
    top: -11px;
    text-align: center;
  }
}
