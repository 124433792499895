@import '../../styles/variables';

.content {
  transition: padding-top 300ms;
  padding-top: $header-height; // fixed header height
  min-height: 100%;
  display: flex;
  flex-direction: column;

  &.inProject {
    padding-top: $header-height-project; // fixed header height
  }
}
