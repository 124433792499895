$checkboxSize: 20px;
$checkboxBorderSize: 2px;
.checkboxWithLabel {
  &.clickable {
    cursor: pointer;
    .checkboxContainer {
      .checkbox {
        cursor: pointer;
      }
    }
    label {
      cursor: pointer;
    }
  }

  .checkboxContainer {
    .checkbox {
      cursor: default;
      border: $checkboxBorderSize solid black;
      padding: 0;

      .background {
        width: $checkboxSize;
        height: $checkboxSize;
        transition: opacity 200ms;
        opacity: 0;
      }

      &.checked {
        .background {
          opacity: 1;
        }
      }
    }
  }

  &:hover {
    .checkboxContainer {
      .checkbox {
        .background {
          opacity: 0.4;
        }
        &.checked {
          .background {
            opacity: 0.9;
          }
        }
      }
    }
  }

  label {
    font-size: 1.2rem;
    line-height: $checkboxSize + ($checkboxBorderSize * 2);
    font-weight: normal;
    margin-left: 0.65rem;
    margin-bottom: 0;
  }
}
