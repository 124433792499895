.toIntakeButton {
  width: 200px;
  height: 145px;
  margin: auto;

  .label {
    height: 100%;
    padding-top: 17%;
  }
}
