@import '../../../styles/variables';

.submitWarning {
  color: $color-blue;
}

.submitButton {
  width: 8rem;
  height: 6rem;

  .label {
    height: 100%;
    padding-top: 16%;
  }
}
