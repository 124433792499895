$color-target-group-unknown: #bab5d8;
$color-target-group-known: #898bb2;

$color-target-group-complying: #9c27b0;
$color-target-group-unintentional-complier: #ce5ee7;
$color-target-group-spontaneous: #e1bee7;
$color-target-group-deterred: #ce93d8;

$color-target-group-offender: #e91e63;
$color-target-group-unintentional-offender: #f8bbd0;
$color-target-group-opportunity: #f06292;
$color-target-group-principled: #ec407a;
$color-target-group-calculating: #c2185b;
$color-target-group-undefined: lightgrey;

$color-blue-dark: #0172ac;
$color-blue: #0fb5f9;
$color-blue-medium: #47c2fa;
$color-blue-light: #b0e7fc;
$color-blue-lighter: #ccebf5;

$color-red: #da125f;
$color-red-medium: #f16192;
$color-red-light: #fabbd0;

$color-navy-dark: #212529;

$color-gray: #747474;
$color-gray-medium: #a3a3a3;
$color-gray-light: #d9d9d9;
$color-gray-lighter: #f4f4f4;

$text-color: #707070;
$text-color-dark: $color-navy-dark;

$color-step-new: $color-blue-light;
$color-step-analysis: #cfc3e9;
$color-step-analysis-medium: #9572d1;
$color-step-analysis-dark: #5d2eb4;
$color-step-distribution: $color-red-light;
$color-step-distribution-medium: $color-red-medium;
$color-step-distribution-dark: $color-red;
$color-step-result: #b1dfdc;
$color-step-detail: #dceec6;

$header-height: 88px;
$header-height-project: 125px;

$grid-breakpoints: (
  xs: 0,
  sm: 1px,
  md: 1px,
  lg: 1px,
  xl: 1025px,
);

// Your variable overrides
$font-size-base: 1.1rem;

$primary: $color-blue;
$danger: $color-red;

$container-max-widths: (
  xs: 1024px,
  sm: 1024px,
  md: 1024px,
  lg: 1024px,
  xl: 1280px,
);

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4.5,
  7: $spacer * 6,
);

//$border-radius: 0;
//$border-radius-lg: 0;
//$border-radius-sm: 0;
$input-border-radius: 0;
$btn-border-radius-lg: 0;
$btn-border-radius-sm: 0;
$btn-border-radius: 0;

$btn-font-size: 1.15rem;
$btn-focus-width: 0;
$btn-focus-box-shadow: 0;
$btn-border-width: 0;

$input-bg: $color-blue-light;
$input-border-width: 0;
$input-focus-bg: none;
$input-focus-width: 0;
$input-focus-box-shadow: 0 0 0;
$input-btn-font-size-sm: 1rem;

$form-label-font-size: 0.8rem;

$form-feedback-font-size: 1rem;

$nav-link-padding-x: 0.5rem;
$nav-link-padding-y: 0.25rem;

$modal-content-border-width: 0px;
$modal-content-border-radius: 1rem;
$modal-header-padding: 2rem;
$modal-inner-padding: 1rem 2rem 2rem;
$modal-footer-margin-between: 1.5rem;

$input-btn-focus-width: 0;

$card-border-radius: 1rem;
