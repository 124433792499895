.IKButton {
  background: url(./assets/button.svg) no-repeat;
  border: none;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  font-size: 1.5rem;
  line-height: 1.5rem;

  &:hover:enabled {
    background-image: url(./assets/button-flipped.svg);
  }

  &.priority {
    font-weight: 700;
  }
}
