.header {
  position: relative;
  text-align: center;

  h1 {
    color: white;
    text-transform: uppercase;
    line-height: 1.4;
    margin-bottom: 0;
  }
}
