@import '../../../../styles/variables';

.introSteps {
  .introStep {
    border: none;
    border-radius: 0;

    .head {
      background: none;
      border: none;
      padding: 0;
      margin-bottom: 0;
      display: flex;
      cursor: pointer;

      .headContent {
        border-bottom: 5px solid grey;
        min-height: 45px;
        display: flex;
        align-items: center;
        flex-grow: 1;

        .title {
          flex-grow: 1;
          font-weight: 700;
          font-size: 21px;
          line-height: 1.1;
          margin-bottom: 0;
        }

        i {
          padding: 0.5rem;
        }
      }

      h3 {
        margin-bottom: 0;
      }

      .icon {
        background-repeat: no-repeat;
        width: 45px;
        height: 45px;
        font-size: 1.2rem;
        line-height: 1.2rem;
        margin-left: 0;
        margin-right: 15px;
        padding: 14px 0 0 17px;
      }
    }

    .content {
      padding: 1rem;
    }

    &.new {
      .headContent {
        border-bottom-color: $color-blue;
      }
      .icon {
        background-image: url(../../../../assets/images/menu-bg-blue.svg);
      }
    }

    &.analysis {
      .headContent {
        border-bottom-color: #5e35b1;
      }
      .icon {
        background-image: url(../../../../assets/images/menu-bg-purple.svg);
      }
    }

    &.distribution {
      .headContent {
        border-bottom-color: $color-target-group-principled;
      }
      .icon {
        background-image: url(../../../../assets/images/menu-bg-red.svg);
      }
    }

    &.result {
      .headContent {
        border-bottom-color: #00897b;
      }
      .icon {
        background-image: url(../../../../assets/images/menu-bg-green.svg);
      }
    }

    &.detail {
      .headContent {
        border-bottom-color: #7ab439;
      }
      .icon {
        background-image: url(../../../../assets/images/menu-bg-light-green.svg);
      }
    }
  }
}
