.backButton {
  width: 8rem;
  height: 6rem;

  .label {
    height: 100%;
    padding-top: 16%;
    font-weight: bold;
  }
}
