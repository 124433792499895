@import '../../../analysis/components/analysisQuestion/analysisQuestion.module';

.analysisQuestionModal {
  padding-top: 10rem;
  padding-left: 12rem;

  .header {
    padding-bottom: 1rem;
    color: white;

    .type {
      font-size: 1rem;
      margin: 0;
    }

    .title {
      line-height: 1.1;
    }
  }

  .body {
    padding: 0;
  }

  .answers {
    width: 100%;
    margin-left: -15px;
    margin-right: -15px;
  }

  p {
    margin-bottom: 0;
  }
}

.answer {
  @extend .answer;
  height: 78px;
  cursor: default;
  border: 0 solid white;
  border-right: 1px solid white;
  border-left: 1px solid white;

  &.selected {
    border-width: 0.4rem;
    padding: 0.2rem;
  }

  &:hover {
    opacity: 1;
  }
}

.unknownAnswer {
  background-image: repeating-linear-gradient(
    to right,
    transparent,
    transparent 4px,
    #ffffff 5px,
    #ffffff 6px
  );
  font-size: 0.9rem;
  line-height: 0.9rem;
}

.infoModal {
  z-index: 1052;
}

.infoModalBackdrop {
  z-index: 1051;
}
