.compassCenter {
  position: absolute;
  z-index: 1;

  > svg {
    animation: scaleOut 1s;
    transform-origin: center;
  }

  &.show {
    > svg {
      animation: scaleIn 1s;
      transform: scale(1);
    }
  }
}

@keyframes scaleIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes scaleOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
