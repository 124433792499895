@import '../../../styles/variables';

.column {
  border-left: 4px solid $color-gray-light;
  border-right: 4px solid $color-gray-light;

  &:first-child {
    border-left: 0;
  }
  &:last-child {
    border-right: 0;
  }
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  h2 {
    margin-bottom: 0;
    margin-left: 10px;
  }
}

.sunburstContainer {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: -1rem;
}

.exampleStackContainer {
  height: 300px;
}

.savedExamples {
  min-height: 300px;
}
