@import '../../styles/variables';

.infoButton {
  background: none;
  border: none;
  position: relative;
  width: 26px;
  height: 26px;
  color: white;
  background-color: $color-blue;
  border-radius: 50%;
  font-size: 20px;
  font-weight: bolder;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -1px;

  &:hover {
    text-decoration: none;
    background-color: $color-gray-medium;
  }
}
