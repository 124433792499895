@import '../../styles/variables';

.contentImageBackground {
  position: relative;
  overflow: hidden;
  min-height: 220px;
  padding-bottom: 120px;
  margin-bottom: -100px;
  z-index: 0;

  &:before {
    position: absolute;
    z-index: -2;
    content: '';
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: url(./assets/home_background.jpg) no-repeat center;
    filter: grayscale(100%) blur(3px);
    background-size: cover;
  }
  &:after {
    position: absolute;
    z-index: -1;
    content: '';
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: $color-blue-dark;
    opacity: 0.8;
  }
}
