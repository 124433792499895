@import 'fonts.scss';
@import '_normalize.scss';
@import '_variables.scss';
@import '_custom.scss';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/solid';

html,
body {
  font-family: PannoText, Helvetica, Arial;
  min-width: 1024px;
  height: 100%;
  color: $text-color;
}
body {
  background-color: white;
}

#root {
  height: 100%;
}

h1 {
  font-weight: 700;
  font-size: 45px;
  line-height: 1.1;
  color: $color-blue;
  margin-bottom: 2.4rem;
}

h2 {
  font-weight: 700;
  font-size: 30px;
  line-height: 1.1;
  color: $color-navy-dark;
}

h3 {
  font-weight: 700;
  font-size: 21px;
  line-height: 1.1;
  margin-bottom: 1.4rem;
}

h4 {
  font-weight: 700;
  font-size: 18px;
}

form .is-invalid {
  background: $color-red-light;
}

.btn:disabled {
  background-color: $color-gray;
}

.btn-outline-primary {
  border: 1px solid $color-blue;
}

.grecaptcha-badge {
  visibility: hidden;
}

.modal-header {
  padding-bottom: 0;
}

button {
  outline: 0 !important;
}
