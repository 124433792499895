@import '../../styles/variables';
@import '~bootstrap/scss/mixins.scss';

.columnContent {
  border-radius: 2rem;
  overflow: hidden;
}

.footer {
  background-color: $color-blue-light;
  color: $color-blue;

  p {
    margin-bottom: 0;
  }

  .footerContactInfo {
    min-width: 140px;
    border-left: 1px solid $color-blue;
  }
}

.centerColumn {
  box-shadow: 0 0 10px rgba(black, 0.2);
  z-index: 1;
  padding-left: map-get($spacers, 7);
  padding-right: map-get($spacers, 7);

  @include media-breakpoint-down(xl) {
    padding-left: map-get($spacers, 5);
    padding-right: map-get($spacers, 5);
  }

  > h3 {
    margin-top: 3rem;
  }
  > h4 {
    margin-top: 1.5rem;
  }
}

.leftColumn {
  background-color: $color-blue;

  * {
    color: white;
  }

  a {
    text-decoration: underline;
  }
}

.leftColumn,
.rightColumn {
  min-width: 140px;
  max-width: 16rem;

  @include media-breakpoint-up(xl) {
    max-width: 18rem;
  }
}
