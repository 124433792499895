.closeBtn {
  background: none;
  border: none;
  transition: opacity 200ms;
  width: 24px;
  height: 24px;
  padding: 0;

  &:hover {
    opacity: 0.7;
  }
}
