@import '../../styles/variables';

.stepIndicator {
  background: url(../../assets/images/menu-bg-grey.svg) no-repeat;
  width: 45px;
  height: 45px;
  padding: 13px 18px;
  font-size: 1.3rem;
  line-height: 1.3rem;
  font-weight: 500;
  color: white;

  &.active,
  &.isHoverable:hover {
    color: $text-color;
    &.new {
      background-image: url(../../assets/images/menu-bg-blue.svg);
    }
    &.analysis {
      background-image: url(../../assets/images/menu-bg-purple.svg);
    }
    &.distribution {
      background-image: url(../../assets/images/menu-bg-red.svg);
    }
    &.result {
      background-image: url(../../assets/images/menu-bg-green.svg);
    }
    &.detail {
      background-image: url(../../assets/images/menu-bg-light-green.svg);
    }
  }
}
