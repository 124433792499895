.mainMenu {
  position: relative;
  min-height: 400px;
  padding-left: 50px;

  .main {
    position: absolute;
    width: 312px;
    height: 220px;
    top: 100px;

    .label {
      height: 100%;
      padding-top: 18%;
    }
  }
}
